define(['positrace'], function (Positrace) {

    const CrossStorageClient = require('cross-storage').CrossStorageClient;

    var RememberService = Positrace.Object.extend({

        initialize: function () {
            this.app = this.getOption('app');
            return Positrace.Object.prototype.initialize.apply(this, arguments);
        },

        getIframeUrl: function () {
            return location.protocol + '//' + location.host.replace(/.*-/, '') + '/local_storage'
        },

        initLocalStorage: function () {
            this.storage = new CrossStorageClient(this.getIframeUrl(), {timeout: 30000});
            return this.storage.onConnect();
        },

        //onLogout: function () {
        //    return this.sessionStorage.reset(null);
        //},

        val: function (key, value, defaultValue) {
            if (_.isUndefined(value)) {
                return this.get(key);
            } else {
                return this.set(key, value, defaultValue);
            }
        },

        set: function (key, value) {
            if (value === null) {
                return this.storage.del(key);
            } else {
                return this.storage.set(key, value);
            }
        },

        get: function (key) {
            return this.storage.get(key);
        }

    });

    return RememberService;

});