define([
    'positrace'
], function (Positrace) {

    var UrlBuilder = Positrace.UrlBuilder.extend({

        setConfig: function (options) {
            Positrace.UrlBuilder.prototype.setConfig.apply(this, arguments);
            this.locale = options.locale
        },

        localize: function (path) {
            path = this.trimPath(path);
            return "/" + this.locale + "/" + path;
        },

        isProductionEnv: function (options) {
            if (!options || !options.host) {
                return false;
            }
            var host = options.host.toLowerCase();

            var isDevelop = /localhost/.test(host);
            var isStaging = /staging/.test(host);

            return !(isDevelop || isStaging);
        }

    });

    return UrlBuilder;
});